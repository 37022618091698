export function getBannerTemplateByTemplateId(templateId: string) {
	const id = !templateId.match(/^.*:/) ? `legacy:${templateId}` : templateId;
	const template = TEMPLATES.find((template) => template.templateId === id);
	const [set, name] = id.split(":");
	return { ...template, src: `/cdn/banner/templates/${set}/${name}.png` };
}

export type BannerTemplate = {
	templateId: string;
	set: string;
	name: string;
	category: string;
	fontColor?: string;
	src?: string;
};

export const TEMPLATES = [
	{ templateId: "marcus-2024:17-mai", set: "marcus-2024", name: "17-mai", category: "general" },
	{ templateId: "marcus-2024:alarm-signal", set: "marcus-2024", name: "alarm-signal", category: "general" },
	{ templateId: "marcus-2024:balkonger", set: "marcus-2024", name: "balkonger", category: "general" },
	{ templateId: "marcus-2024:bandtvang", set: "marcus-2024", name: "bandtvang", category: "general" },
	{ templateId: "marcus-2024:bod", set: "marcus-2024", name: "bod", category: "general" },
	{ templateId: "marcus-2024:dugnad", set: "marcus-2024", name: "dugnad", category: "general" },
	{ templateId: "marcus-2024:elbil-lader", set: "marcus-2024", name: "elbil-lader", category: "general" },
	{ templateId: "marcus-2024:generelle-regler", set: "marcus-2024", name: "generelle-regler", category: "general" },
	{ templateId: "marcus-2024:gress", set: "marcus-2024", name: "gress", category: "general" },
	{ templateId: "marcus-2024:grill", set: "marcus-2024", name: "grill", category: "general" },
	{ templateId: "marcus-2024:gronn", set: "marcus-2024", name: "gronn", category: "general" },
	{ templateId: "marcus-2024:host", set: "marcus-2024", name: "host", category: "general" },
	{ templateId: "marcus-2024:internet", set: "marcus-2024", name: "internet", category: "general" },
	{ templateId: "marcus-2024:kvinnedagen", set: "marcus-2024", name: "kvinnedagen", category: "general" },
	{ templateId: "marcus-2024:lufting-av-hund", set: "marcus-2024", name: "lufting-av-hund", category: "general" },
	{ templateId: "marcus-2024:oppussing", set: "marcus-2024", name: "oppussing", category: "general" },
	{ templateId: "marcus-2024:paske", set: "marcus-2024", name: "paske", category: "general" },
	{ templateId: "marcus-2024:ramadan", set: "marcus-2024", name: "ramadan", category: "general" },
	{ templateId: "marcus-2024:romningsveier", set: "marcus-2024", name: "romningsveier", category: "general" },
	{ templateId: "marcus-2024:rotete", set: "marcus-2024", name: "rotete", category: "general" },
	{ templateId: "marcus-2024:roykvarslere", set: "marcus-2024", name: "roykvarslere", category: "general" },
	{ templateId: "marcus-2024:skjoteledning", set: "marcus-2024", name: "skjoteledning", category: "general" },
	{ templateId: "marcus-2024:sluk", set: "marcus-2024", name: "sluk", category: "general" },
	{ templateId: "marcus-2024:soeppeltomming", set: "marcus-2024", name: "soeppeltomming", category: "general" },
	{ templateId: "marcus-2024:sommer", set: "marcus-2024", name: "sommer", category: "general" },
	{ templateId: "marcus-2024:styremoete", set: "marcus-2024", name: "styremoete", category: "general" },
	{ templateId: "marcus-2024:tomgangskjoering", set: "marcus-2024", name: "tomgangskjoering", category: "general" },
	{ templateId: "marcus-2024:uvedkommende", set: "marcus-2024", name: "uvedkommende", category: "general" },
	{ templateId: "marcus-2024:vannlekasje", set: "marcus-2024", name: "vannlekasje", category: "general" },
	{ templateId: "marcus-2024:vennskap", set: "marcus-2024", name: "vennskap", category: "general" },
	{ templateId: "marcus-2024:verktoy", set: "marcus-2024", name: "verktoy", category: "general" },
	{ templateId: "legacy:bunny", set: "legacy", name: "bunny", category: "seasonal", fontColor: "dark" },
	{ templateId: "legacy:17-may", set: "legacy", name: "17-may", category: "seasonal" },
	{ templateId: "legacy:autum", set: "legacy", name: "autum", category: "seasonal" },
	{ templateId: "legacy:barbequing", set: "legacy", name: "barbequing", category: "rules" },
	{ templateId: "legacy:cigarette", set: "legacy", name: "cigarette", category: "rules" },
	{ templateId: "legacy:clear-exits", set: "legacy", name: "clear-exits", category: "rules" },
	{ templateId: "legacy:close-door", set: "legacy", name: "close-door", category: "rules" },
	{ templateId: "legacy:dog-backyard", set: "legacy", name: "dog-backyard", category: "rules" },
	{ templateId: "legacy:drainage", set: "legacy", name: "drainage", category: "general" },
	{ templateId: "legacy:dugnad", set: "legacy", name: "dugnad", category: "rules" },
	{ templateId: "legacy:electrical", set: "legacy", name: "electrical", category: "hse" },
	{ templateId: "legacy:fire", set: "legacy", name: "fire", category: "hse" },
	{ templateId: "legacy:garage", set: "legacy", name: "garage", category: "rules" },
	{ templateId: "legacy:grass", set: "legacy", name: "grass", category: "rules" },
	{ templateId: "legacy:halloween", set: "legacy", name: "halloween", category: "seasonal" },
	{ templateId: "legacy:handyman", set: "legacy", name: "handyman", category: "general" },
	{ templateId: "legacy:meeting", set: "legacy", name: "meeting", category: "general" },
	{ templateId: "legacy:quiet", set: "legacy", name: "quiet", category: "rules" },
	{ templateId: "legacy:roof-terrace", set: "legacy", name: "roof-terrace", category: "rules" },
	{ templateId: "legacy:summer", set: "legacy", name: "summer", category: "seasonal" },
	{ templateId: "legacy:trash", set: "legacy", name: "trash", category: "general" },
	{ templateId: "legacy:winter", set: "legacy", name: "winter", category: "seasonal" },
	{ templateId: "legacy:warning", set: "legacy", name: "warning", category: "general" },
];
